<script>
    export default {
        name: "TypographyWrapper",
    };
</script>

<template>
    <div class="typography-wrapper">
        <slot></slot>
    </div>
</template>

<style lang="scss">
    .typography-wrapper {
        padding-top: 24px;

        @include bp($bp-desktop-sm) {
            padding-top: 56px;
        }

        h2 {
            font-weight: var(--typography-heading-weight);
            font-size: 26px;
            line-height: 40px;
            margin-bottom: 16px;

            @include bp($bp-desktop-sm) {
                font-size: 36px;
                line-height: 40px;
                margin-bottom: 32px;
            }
        }

        p {
            margin-bottom: 8px;
            color: var(--typography-text-color);
            font-size: 14px;
            font-weight: var(--typography-text-weight);
            line-height: 28px;

            @include bp($bp-desktop-sm) {
                margin-bottom: 12px;
                font-size: 16px;
                line-height: 34px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            a {
                color: var(--info-text--dark);
            }
        }

        ul,
        ol {
            margin-bottom: 20px;
            padding-left: 20px;
            color: var(--typography-text-color);
            li {
                margin-bottom: 5px;
                font-size: 15px;
                font-weight: var(--typography-text-weight);
                line-height: 24px;
            }
        }

        ol {
            list-style: decimal;
        }
    }
</style>
