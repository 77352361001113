<script>
    import Accordion from "@/components/Accordion";
    import LinkWithAnalytics from "@/components/LinkWithAnalytics";

    export default {
        name: "GuaranteeContent",
        components: {
            Accordion,
            LinkWithAnalytics,
        },
        props: {
            text: {
                type: Array,
                required: true,
            },
            brandName: {
                type: String,
                required: true,
            },
            email: {
                type: String,
                required: true,
            },
            period: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                activeIndex: 0,
            };
        },
        methods: {
            changeIndex(index) {
                this.activeIndex = index;
            },
        },
    };
</script>

<template>
    <div class="b-text-page">
        <div class="b-text-delivery">
            <h2>Гарантия и возврат</h2>
            <div class="b-text-delivery__content">
                <div class="b-text-delivery__accordion">
                    <accordion
                        :active-index="activeIndex"
                        :index="i"
                        @change="changeIndex"
                        :accordion="item"
                        v-for="(item, i) in text"
                        :key="i + 10"
                    >
                    </accordion>
                </div>
            </div>
        </div>

        <div class="b-text-payment">
            <h2>Срок гарантии</h2>

            <div class="b-text-payment__content">
                <div
                    class="b-text-payment__title"
                    v-html="
                        `На&nbsp;все товары ${brandName} устанавливается гарантия сроком ${period} со&nbsp;дня продажи.`
                    "
                ></div>

                <div
                    class="b-text-payment__text"
                    v-html="
                        'Гарантия покрывает производственные дефекты и&nbsp;не&nbsp;распространяется на&nbsp;повреждения, связанные с&nbsp;использованием в&nbsp;целях, для которых товар не&nbsp;был предназначен, в&nbsp;т.ч. нарушение инструкций производителя.'
                    "
                ></div>
                <br />
                <div
                    class="b-text-payment__text"
                    v-html="
                        'Повреждения, вызванные естественным износом при эксплуатации, также не&nbsp;покрываются гарантией.'
                    "
                ></div>
            </div>

            <div class="b-text-payment__caption">Нужна помощь? <br />Жмите сюда</div>

            <link-with-analytics class="b-text-payment__btn" type="email" :text="email">
                Напишите нам — {{ email }}
            </link-with-analytics>
        </div>
    </div>
</template>

<style lang="scss">
    @import "~@/assets/sass/service-page.scss";
</style>
