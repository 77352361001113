<script>
    import { metaInfo } from "@/core/MetaInfo";
    import TypographyWrapper from "@/components/TypographyWrapper";
    import GuaranteeContent from "@/components/GuaranteeContent";
    import GuaranteeList from "@tb/components/GuaranteeList";

    export default {
        name: "Guarantee",
        components: {
            GuaranteeContent,
            TypographyWrapper,
            GuaranteeList,
        },
        data() {
            return {
                text: [
                    {
                        title: "Гарантийное обслуживание",
                        desc: [
                            "В&nbsp;своей работе мы&nbsp;в&nbsp;первую очередь ориентируемся на&nbsp;пожелания покупателя. Нашей основной целью являются твердое следование букве закона о&nbsp;защите прав потребителя. Мы&nbsp;стараемся максимально сократить все бюрократические моменты, связанные с&nbsp;обращением в&nbsp;сервис-центр.",
                            "Если вы&nbsp;сомневаетесь в&nbsp;наличии гарантийного случая или у&nbsp;вас есть вопросы технического характера, смело пишите нам на&nbsp;электронную почту <a onmousedown=\"dataLayer.push({'event': 'contacts'}); ym(${this.$_ymId},'reachGoal','contacts');\" ontouchstart=\"dataLayer.push({'event': 'contacts'}); ym(${this.$_ymId},'reachGoal','contacts');\" href=\"mailto:support@travel-blue.ru\" >support@travel-blue.ru</a > &nbsp;&mdash; сотрудники службы поддержки помогут в&nbsp;решении вашего вопроса, проведут первичную консультацию по&nbsp;электронной почте, а&nbsp;в&nbsp;случае, если это не&nbsp;поможет, дадут указания для дальнейших действий по&nbsp;инициированию гарантийного случая.",
                        ],
                    },
                    {
                        title: "Возврат и обмен устройств в течение 14 дней после покупки",
                        desc: [
                            "В&nbsp;случае, если вы&nbsp;решили отказаться от&nbsp;товара в&nbsp;течение 2&nbsp;недель с&nbsp;момента покупки, перед процедурой возврата и&nbsp;возмещения оплаченных средств вам также необходимо сдать покупку на&nbsp;первичную диагностику&nbsp;&mdash; для подтверждения отсутствия механических повреждений (чтобы мы&nbsp;понимали, что возвращаем деньги не&nbsp;за&nbsp;поврежденный клиентом товар). Такую диагностику мы&nbsp;стараемся делать быстрее, чем регламентируемые 14&nbsp;дней (обычно она занимает 2&ndash;4 рабочих дня, если отсутствует гарантийный случай, а&nbsp;требуется только проверить товарный вид).",
                            "После диагностики и&nbsp;подтверждения сервисным центром отсутствия у&nbsp;товара повреждений, которые могут быть связаны с&nbsp;небрежной эксплуатацией, с&nbsp;вами свяжется сотрудник и&nbsp;согласует формат возврата денежных средств. Форма возврата соответствует форме оплаты: при оплате наличными возвращаются наличные, при оплате банковской картой инициируется возвратный платеж на&nbsp;банковскую карту. Средства, оплаченные за&nbsp;доставку, не&nbsp;возвращаются.",
                        ],
                    },
                ],
            };
        },
        metaInfo() {
            return metaInfo.getMeta("guarantee");
        },
    };
</script>

<template>
    <div class="l-container">
        <typography-wrapper>
            <guarantee-content
                :text="text"
                brand-name="Travel Blue"
                email="support@travel-blue.ru"
                period="24 месяца"
            ></guarantee-content>
        </typography-wrapper>

        <div class="b-text-page__guarantee">
            <guarantee-list></guarantee-list>
        </div>
    </div>
</template>
