<script>
    import { isMobile } from "@/utils";
    import TheSvgIcon from "@main/packages/TheSvgIcon";
    import TransitionContainer from "@/components/TransitionContainer";

    export default {
        name: "Accordion",
        components: {
            TransitionContainer,
            TheSvgIcon,
        },
        props: {
            accordion: Object,
            customType: Boolean,
            index: [Number, String],
            activeIndex: [Number, String],
        },
        data() {
            return {
                isShow: false,
            };
        },
        computed: {
            hashId() {
                return `${this.accordion.sectionId}_${this.accordion.id}`;
            },
        },
        methods: {
            showDropdown() {
                if (isMobile()) {
                    if (this.index === this.activeIndex) {
                        this.$emit("change", 999);
                    } else {
                        this.$emit("change", this.index);
                        setTimeout(() => {
                            this.move(this.$refs.jsAccordion.offsetTop - 60);
                        }, 300);
                    }
                } else {
                    if (this.index === this.activeIndex) {
                        this.$emit("change", 999);
                    } else {
                        this.isShow = !this.isShow;
                    }
                }
                this.$emit("set-anchor", this.hashId);
            },
            move(amount) {
                scrollTo({
                    top: amount,
                    behavior: "smooth",
                });
            },
        },
    };
</script>

<template>
    <div :id="hashId" class="b-accordion" ref="jsAccordion">
        <button class="b-accordion__title outline" @click="showDropdown">
            <div class="b-accordion__title-text">
                {{ accordion.title }}
            </div>

            <div
                class="b-accordion__title-arrow"
                :class="{
                    'b-accordion__title-arrow--rotate': activeIndex === index || isShow,
                }"
            >
                <the-svg-icon name="arrow"></the-svg-icon>
            </div>
        </button>
        <transition-container :show="activeIndex === index || isShow">
            <div v-if="customType" class="b-accordion__desc" v-html="accordion.desc"></div>
            <div v-else class="b-accordion__desc">
                <p v-for="(desc, i) in accordion.desc" :key="i" v-html="desc"></p>
            </div>
        </transition-container>
    </div>
</template>

<style lang="scss">
    .b-accordion {
        padding: var(--accordion-inner-padding);
        margin-top: -1px;
        &:not(:first-child) {
            border-color: var(--service-border-divider);
            border-width: 1px 0 0 0;
            border-style: solid;
            border-image: var(--border-image-gradient);
            border-image-slice: 1;
        }
        &:not(:last-child) {
            border-color: var(--service-border-divider);
            border-width: 0 0 1px 0;
            border-style: solid;
            border-image: var(--border-image-gradient);
            border-image-slice: 1;
        }
    }

    .b-accordion__title {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-right: 40px;
        text-align: left;
        cursor: pointer;
    }

    .b-accordion__title-text {
        color: var(--service-subheading-color);
        font-size: 16px;
        font-weight: var(--service-subheading-weight);
        line-height: 28px;
        text-align: left;
        @include bp($bp-desktop-sm) {
            letter-spacing: 0.005em;
        }
    }

    .b-accordion__title-arrow {
        position: absolute;
        right: 0;
        top: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: var(--service-arrow-size--outer);
        height: var(--service-arrow-size--outer);
        border-radius: 50%;
        color: var(--service-arrow-fill);
        flex-shrink: 0;
        transform: translateY(-50%);
        transition: background-color 0.2s ease-in;
        svg {
            width: var(--service-arrow-size--inner);
            height: var(--service-arrow-size--inner);
            flex-shrink: 0;
            transform: rotate(-90deg);
            transition: transform 0.2s ease-in;
        }
    }

    .b-accordion__title-arrow--rotate {
        color: var(--base-white);
        background-color: var(--service-arrow-fill);
        svg {
            transform: rotate(90deg);
        }
    }

    .b-accordion__desc {
        padding-top: 16px;

        a {
            &:hover {
                text-decoration: underline;
            }
        }

        ul {
            margin-bottom: 12px;

            li {
                position: relative;
                font-size: 14px;
                line-height: 22px;

                @include bp($bp-desktop-sm) {
                    font-size: 16px;
                }

                padding-left: 10px;

                &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 9px;
                    @include box(4px);
                    border-radius: 50%;
                    background-color: var(--info--light);

                    @include bp($bp-desktop-sm) {
                        top: 10px;
                    }
                }

                &:not(:first-child) {
                    margin-top: 4px;
                }
            }
        }

        .iframe-wrapper {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;
            border-radius: var(--border-lg);
            overflow: hidden;
        }
        iframe {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
</style>
